import { Form, FormikProps, withFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getPath } from '../../../helpers/path';
import { datePassed } from '../../../helpers/time';
import { getWarehouseApiUrl } from '../../../helpers/url';
import {
    AmountInfo, getAmountInfo, getCustomCapacityAmountInfo, MAX_AMOUNT,
} from '../../../helpers/warehouse/actual-states';
import { getProductVariantName } from '../../../helpers/warehouse/names';
import { PATH as WAREHOUSE_PRODUCT_VARIANT_PATH } from '../../../routes/warehouse/ProductVariant';
import { PATH as WAREHOUSE_STORAGE_PATH } from '../../../routes/warehouse/Storage';
import { ActualStateModel } from '../../../types/warehouse/model';
import AmountInputField from '../../FormField/AmountInputField';
import Tooltip from '../../Tooltip/Tooltip';
import './ActualState.css';

export enum ActualStateContext {
    PRODUCT_VARIANT = 'product-variant',
    STORAGE = 'storage',
    TAG = 'tag',
}

interface FormValues {
    amount: number;
}

const getSchema = (maxAmount: number) => Yup.object().shape({
    amount: Yup.number().typeError('form.number.type-incorrect')
        .min(0, 'form.number.too-low')
        .max(maxAmount, maxAmount !== MAX_AMOUNT ? 'form.amount.capacity-limit' : 'form.number.too-high')
        .required('form.element.required'),
});

interface PureFormProps {
    amountInfo: AmountInfo;
    context: ActualStateContext;
    maxAmount: number;
    saved: boolean | null;
    savedAmount: number;
    setMaxAmount: (maxAmount: number) => void;
    state: ActualStateModel;
}

const PureForm = ({ context, errors, state, touched, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const [details, setDetails] = useState<boolean>(false);
    const [showSaved, setShowSaved] = useState(true);
    const tooltipRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        setShowSaved(true);
    }, [props.saved]);

    return <div className="ActualState toast show" style={details ? {} : { cursor: 'pointer' }} onClick={() => {
        if (!details) {
            setDetails(true);
        }
    }}>
        <div className="toast-header">
            <strong className="mr-auto">
                {props.values.amount} {props.amountInfo.unit}
                {props.amountInfo.fixedMax ?
                    ` (${Math.round(props.values.amount / props.amountInfo.storageAmount! * 100)}%)` :
                    null}
            </strong>
            {state.useBy ?
                <small className={`text-muted${datePassed(state.useBy) ? ' text-danger' : ''}`}>
                    <FormattedMessage id="actual-state.use-by" defaultMessage="Use by: {date}"
                        values={{ date: (new Date(state.useBy)).toLocaleDateString() }} />
                </small> :
                null}
            {state.useBy && state.bestBefore ? <>&nbsp; &nbsp;</> : null}
            {state.bestBefore ?
                <small className={`text-muted${datePassed(state.bestBefore) ? ' text-warning' : ''}`}>
                    <FormattedMessage id="actual-state.best-before" defaultMessage="Best before: {date}"
                        values={{ date: (new Date(state.bestBefore)).toLocaleDateString() }} />
                </small> :
                null}
        </div>
        {context !== ActualStateContext.STORAGE && state.storage && state.storage.name ?
            <div className="toast-body text-break">
                <FormattedMessage id="table.label.storage" defaultMessage="Storage:" />{' '}
                <Link to={{
                    pathname: getPath(WAREHOUSE_STORAGE_PATH, { storageId: state.storage.id }),
                    state: { storage: state.storage }
                }}>{state.storage.name}</Link>
            </div> :
            null}
        {context !== ActualStateContext.PRODUCT_VARIANT && state.productVariant ?
            <div className="toast-body text-break">
                <FormattedMessage id="table.label.product" defaultMessage="Product:" />{' '}
                <Link to={{
                    pathname: getPath(
                        WAREHOUSE_PRODUCT_VARIANT_PATH, { productVariantId: state.productVariant.id },
                    ),
                    state: { productVariant: state.productVariant }
                }}>{getProductVariantName(state.productVariant)}</Link>
            </div> :
            null}
        {details ?
            <div className="toast-body text-break">
                <Form>
                    <AmountInputField fieldId="amount" touched={touched.amount} error={errors.amount} type="range"
                        max={props.maxAmount} step={props.amountInfo.step}
                        unit={`${props.amountInfo.unit}${props.amountInfo.unitSuffix}`}
                        onMouseOver={() => { setShowSaved(false) }}
                        onIncrease={props.amountInfo.fixedMax ? undefined : () => {
                            if (props.maxAmount < MAX_AMOUNT && state.productVariant) {
                                const amountInfo = getCustomCapacityAmountInfo(props.maxAmount, state.productVariant);
                                props.setMaxAmount(amountInfo.currentMax);
                            }
                        }} />
                    <p className="text-center mb-0">
                        <button type="submit" className="btn btn-secondary btn-sm btn-block" ref={tooltipRef}
                            onSubmit={props.validateForm} disabled={props.isSubmitting}
                            onClick={() => { setShowSaved(false) }}>
                            <FormattedMessage id="form.save" defaultMessage="Save" />
                        </button>
                        <Tooltip target={tooltipRef.current} show={props.saved !== null && showSaved} placement="top"
                            onMouseOver={() => { setShowSaved(false) }}
                            variant={props.saved ? 'success' : 'danger'}>
                            {props.saved ?
                                <FormattedMessage id="form.tooltip.success" defaultMessage="Saving success" /> :
                                <FormattedMessage id="form.tooltip.failure" defaultMessage="Saving failure" />}
                        </Tooltip>
                    </p>
                </Form>
            </div> :
            null}
    </div>;
};

interface FormikFormProps extends PureFormProps {
    setSaved: (saved: boolean | null) => void;
    setSavedAmount: (savedAmount: number) => void;
    setState: (state: ActualStateModel) => void;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, setSubmitting }) => {
        props.setSaved(null);
        if (values.amount === props.savedAmount || !props.state.productVariant) {
            setSubmitting(false);
            return;
        }
        try {
            const state = await authenticatedRequest<ActualStateModel>({
                data: {
                    amount: values.amount,
                },
                method: HttpMethod.PATCH,
                url: getWarehouseApiUrl('product-variants/:productVariantId/actual-states/:id', { params: {
                    id: props.state.id, productVariantId: props.state.productVariant.id,
                } }),
            });
            const amountInfo = getAmountInfo(state.amount, props.state.productVariant, state.storage);
            props.setState({ ...props.state, ...state });
            props.setSavedAmount(state.amount);
            props.setMaxAmount(amountInfo.currentMax);
            props.setSaved(true);
        } catch (error) {
            props.setSaved(false);
        }
        setSubmitting(false);
    },
    mapPropsToValues: ({ state }) => ({
        amount: state.amount,
    }),
    validationSchema: (props: FormikFormProps) => getSchema(props.maxAmount),
})(PureForm);

interface ActualStateProps {
    context: ActualStateContext;
    state: ActualStateModel;
}

export default (props: ActualStateProps) => {
    const amountInfo = getAmountInfo(props.state.amount, props.state.productVariant!, props.state.storage);
    const [savedAmount, setSavedAmount] = useState(props.state.amount);
    const [maxAmount, setMaxAmount] = useState(amountInfo.currentMax);
    const [state, setState] = useState<ActualStateModel>(props.state);
    const [saved, setSaved] = useState<boolean | null>(null);

    return <FormikForm {...{
        amountInfo, maxAmount, saved, savedAmount, setMaxAmount, setSaved, setSavedAmount, setState, state,
        context: props.context,
    }} />;
}
