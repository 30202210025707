export interface ActualStateModel {
    amount: number;
    bestBefore: string | null;
    id: number;
    productVariant: ProductVariantModel | null;
    productVariantId: number;
    storage: StorageModel | null;
    storageId: number | null;
    useBy: string | null;
}

export interface CommentModel {
    content: string | null;
    createdAt: string;
    id: number;
    mark: number | null;
    updatedAt: string;
    userName: string | null;
}

export interface ProductUnitModel {
    barcode: string | null;
    description: string | null;
    id: number;
    inServiceFrom: string | null;
    name: string | null;
    nfcTag: string | null;
    productVariant: ProductVariantModel;
    productVariantId: number;
    qrCode: string | null;
    rentalsAvailable: boolean;
    rfidTag: string | null;
    serialNumber: string | null;
    storage: StorageModel | null;
    storageId: number | null;
}

export interface ProductUnitRentalModel {
    comments: string | null;
    endDate: string | null;
    expectedEndDate: string | null;
    id: number;
    isExtended: boolean;
    productUnit?: ProductUnitModel;
    productUnitId: number;
    rentantName: string;
    startDate: string;
}

export interface ProductVariantModel {
    amount: number;
    eanCode: string | null;
    id: number;
    name: string | null;
    product: ProductModel;
    productId: number;
    statesAvailable: boolean;
    step: number;
    unit: string;
    unitsAvailable: boolean;
}

export interface ProductModel {
    description: string | null;
    id: number;
    mark: number | null;
    name: string;
    producer: ProducerModel | null;
    producerId: number | null;
    validityPeriod: number | null;
}

export interface ProducerModel {
    description: string | null;
    id: number;
    mark: number | null;
    name: string;
    notForeignConsortium: boolean;
    productionInFatherland: boolean;
    registeredInFatherland: boolean;
    researchInFatherland: boolean;
}

export enum ResourceTarget {
    BLANK = 'blank',
    SELF = 'self',
    TOP = 'top',
}

export interface ResourceModel {
    description: string | null;
    id: number;
    internal: boolean;
    name: string;
    path: string;
    target: ResourceTarget;
    url: string | null;
}

export interface StorageSimpleModel {
    id: number;
    name: string;
}
export interface StorageModel extends StorageSimpleModel {
    amount: number | null;
    amountInUse: number | null;
    barcode: string | null;
    description: string | null;
    fixedCapacity: boolean;
    nfcTag: string | null;
    parent: StorageModel | null;
    qrCode: string | null;
    rfidTag: string | null;
    statesAvailable: boolean;
    unit: string | null;
    unitsAvailable: boolean;
}

export interface FixedCapacityStorageModel extends StorageModel {
    amount: number;
    amountInUse: number;
    unit: string;
}

export interface TagModel {
    description: string | null;
    id: number;
    name: string;
    slug: string;
}
