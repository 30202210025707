export const SECOND_IN_MS = 1000;
const HOUR_IN_MINUTES = 60;
const DAY_IN_MINUTES = HOUR_IN_MINUTES * 24;
const minutesMultipliers = [HOUR_IN_MINUTES, 1];

function getDatePart(number: number, digitsNumber: number = 0): string {
    let numericString = `${number}`;
    while (numericString.length < digitsNumber) {
        numericString = '0' + numericString;
    }
    return numericString;
}

export function getFormattedDate(date: Date): string {
    return [getDatePart(date.getFullYear(), 4), getDatePart(date.getMonth() + 1, 2), getDatePart(date.getDate(), 2)]
        .join('-');
}

export function getFormattedTime(date: Date, includeSeconds = false): string {
    const timeParts = [getDatePart(date.getHours(), 2), getDatePart(date.getMinutes(), 2)];
    if (includeSeconds) {
        timeParts.push(getDatePart(date.getSeconds(), 2));
    }
    return timeParts.join(':');
}

export function getTimeFromMinutes(time: number, format: string = 'HH:MM'): string {
    const timeAbs = Math.abs(time);
    const items = {
        'HH': Math.floor(timeAbs % DAY_IN_MINUTES / HOUR_IN_MINUTES),
        'MM': Math.floor(timeAbs % HOUR_IN_MINUTES),
    };
    return Object.entries(items).reduce(
        (result, [key, value]) => result.replace(key, `${(value < 10 ? '0' : '')}${value}`), format.toUpperCase(),
    );
}

export function getMinutesFromTime(time: string, periodEnd: boolean = false): number {
    const minutes = time.split(':').reduce(
        (result, value, index) => result +
            (minutesMultipliers[index] ? parseInt(value, 10) * minutesMultipliers[index] : 0),
        0,
    );
    return periodEnd && minutes === 0 ? DAY_IN_MINUTES : minutes;
}

export function datePassed(dateString: string | null, now: Date = new Date()): boolean {
    return !!dateString && new Date(dateString) < now;
}
